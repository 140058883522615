import { Button, Col, Divider, Input, Row, Typography, Upload } from "antd"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"
import { DownloadOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import LoadingSpinner from "../../assets/icons/loading"
import { TransactionsViewModel } from "./TransactionsViewModel"
import { colors } from "../../assets/styles/appStyles"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import DataFilter, { DataFilterRef } from "../../components/DataFilter/DataFilter"
import ProgressIndicator from "../../components/ProgressIndicator/ProgressIndicator"
import TransactionsTable from "./components/TransactionsTable"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { createRef } from "react"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { useNavigate } from "react-router-dom"
import { AuthStore } from "../../stores/AuthStore"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { Transaction } from "../../../domain/entities/Transaction"
import config from "../../../config/config"
import { debounce } from "../../utils/debounce"

const { Title } = Typography

const TransactionsView = () => {
	const { t } = useTranslation("transactions")
	const dataFilterRef = createRef<DataFilterRef>()
	const viewModel = useContainerInjection<TransactionsViewModel>("TransactionsViewModel")
	const navigate = useNavigate()
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")

	useUpdateEffect(() => {
		if (viewModel.pagination < 1) return
		viewModel.fetchPaginatedTransactions(dataFilterRef.current?.filter)
	}, [viewModel.pagination])

	return (
		<>
			<Input
				className="mb-1"
				value={viewModel.searchValue}
				bordered={false}
				inputMode="search"
				allowClear
				onChange={event => {
					viewModel.setSearchValue(event.target.value)
					debounce(() => {
						viewModel.fetchTransactions(dataFilterRef.current?.filter)
					}, config.ui.componentsConfig.searchInputDelay)
				}}
				style={{
					width: "50%",
					backgroundColor: colors.overlapBackground,
					boxShadow: "-3px 5px 10px 1px rgba(0,0,0,0.1)"
				}}
				placeholder={t("searchBar")}
				prefix={<SearchOutlined />}
			/>
			<div className="tabled mt-1">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div className="flex items-center justify-between">
									<div className="flex items-center">
										<Title className="mt-3" level={4}>
											{t("title")}
										</Title>
										{isAdmin && (
											<Button
												style={{ marginLeft: "1.5vw" }}
												type="primary"
												onClick={() => navigate(RoutePaths.TRANSACTIONS_EDIT)}
											>
												{t("addNew")}
											</Button>
										)}
									</div>
									<div
										style={{
											width: 280,
											height: 40,
											display: "flex",
											justifyContent: "center",
											alignItems: "center"
										}}
									>
										<Upload
											accept=".csv"
											showUploadList={false}
											customRequest={async options => {
												try {
													const { file, onSuccess, onError, onProgress } = options
													viewModel.setProgressData({
														active: true,
														message: t("uploading"),
														status: UploadProgressStatus.LOADING,
														file: file as File
													})
													const uploadData = await viewModel.uploadTransactions(file as File)

													viewModel.setProgressData({
														...viewModel.progressData,
														active: true,
														message:
															uploadData.rowNumber && uploadData.rowNumber >= 0
																? `${uploadData.message} (${t("row")}: ${
																		uploadData.rowNumber
																  })`
																: uploadData.message,
														status:
															uploadData.code !== "ok"
																? UploadProgressStatus.ERROR
																: UploadProgressStatus.SUCCESS
													})
													onSuccess && onSuccess({ message: uploadData.message })
												} catch (error) {
													viewModel.setProgressData({
														...viewModel.progressData,
														active: true,
														message: t("uploadingError"),
														status: UploadProgressStatus.ERROR
													})
												}
											}}
										>
											<Button
												icon={viewModel.uploadingFile ? <LoadingSpinner /> : <UploadOutlined />}
												type="link"
												disabled={viewModel.uploadingFile}
												style={viewModel.uploadingFile ? { color: colors.activity } : undefined}
											>
												{t("import")}
											</Button>
										</Upload>
										<Button
											icon={<DownloadOutlined />}
											type="link"
											disabled={viewModel.isLoading}
											style={viewModel.isLoading ? { color: colors.activity } : undefined}
											onClick={() =>
												viewModel.downloadTransactions(dataFilterRef.current?.filter)
											}
										>
											{t("export")}
										</Button>
									</div>
								</div>
							}
						>
							<div className="px-6 duration-500">
								<ProgressIndicator
									visible={viewModel.progressData.active}
									status={viewModel.progressData.status}
									title={viewModel.progressData.file?.name}
									progressMessage={viewModel.progressData.message}
									onClose={() => viewModel.closeProgress()}
								/>
								<DataFilter
									ref={dataFilterRef}
									onReset={() => viewModel.fetchTransactions()}
									onSubmit={async values => await viewModel.fetchTransactions(values)}
								/>
								<Divider className="m-0" />
							</div>
							<TransactionsTable
								data={viewModel.searchedTransactions}
								loading={viewModel.isLoading}
								onChangePage={(page, pageSize, lastPageFetched) => {
									if (viewModel.searchValue) return
									if (page >= lastPageFetched - 1 && viewModel.hasNextPage && !viewModel.isFetching) {
										if (viewModel.pagination > 1 && viewModel.limit !== 200) {
											viewModel.setLimit(200)
											viewModel.setPagination(0)
											return
										}
										viewModel.setPagination(viewModel.pagination + 1)
									}
								}}
								isFetching={viewModel.isFetching}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(TransactionsView)
