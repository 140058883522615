export enum InputType {
	TEXT = "text",
	TEXTAREA = "textarea",
	PASSWORD = "password",
	AUTOCOMPLETE = "autocomplete",
	EMAIL = "email",
	NUMBER = "number",
	TEL = "tel",
	DATE = "date",
	URL = "url"
}

export enum InputAddons {
	HTTPS = "https://"
}
