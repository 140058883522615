import { BillingRepository } from "../../../repositories/billingRepository"
import { UseCase } from "../../useCase"
import { UpdateInvoiceEmissionDateRequest } from "./request"
import { UpdateInvoicesEmissionDateResponse } from "./response"

export class UpdateInvoiceEmissionDateUseCase
	implements UseCase<UpdateInvoiceEmissionDateRequest, UpdateInvoicesEmissionDateResponse>
{
	private _repository
	constructor({ BillingRepository }: { BillingRepository: BillingRepository }) {
		this._repository = BillingRepository
	}
	exec({ invoiceId, emissionDate }: UpdateInvoiceEmissionDateRequest): Promise<UpdateInvoicesEmissionDateResponse> {
		return this._repository.updateInvoiceEmissionDate(invoiceId, emissionDate)
	}
}
