import { action, makeObservable, observable } from "mobx"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { ViewModel } from "../ViewModel"
import { FileService } from "../../../domain/services/FileService"
import { GetInvoicesUseCase } from "../../../domain/useCases/billing/getInvoices"
import { Invoice } from "../../../domain/entities/Invoice"
import { InvoicesFilter } from "../../../domain/types/billing/InvoicesFilter"
import { InvoiceStatus } from "../../../domain/enum/invoiceStatus.enum"
import { ExportInvoicesUseCase } from "../../../domain/useCases/billing/exportInvoices"
import { UpdateInvoicesStatusUseCase } from "../../../domain/useCases/billing/updateInvoicesStatus"
import { UpdateTransactionCreatedDateUseCase } from "../../../domain/useCases/billing/updateTransactions"
import { ExportInvoiceTransactionsUseCase } from "../../../domain/useCases/billing/exportInvoiceTransactions"
import { UpdateInvoiceEmissionDateUseCase } from "../../../domain/useCases/billing/updateInvoiceEmissionDate"
import dayjs from "dayjs"

interface Props {
	ErrorHandler: ErrorHandler
	GetInvoicesUseCase: GetInvoicesUseCase
	ExportInvoicesUseCase: ExportInvoicesUseCase
	ExportInvoiceTransactionsUseCase: ExportInvoiceTransactionsUseCase
	UpdateInvoicesStatusUseCase: UpdateInvoicesStatusUseCase
	UpdateInvoiceEmissionDateUseCase: UpdateInvoiceEmissionDateUseCase
	UpdateTransactionCreatedDateUseCase: UpdateTransactionCreatedDateUseCase
}
type ErrorType = "header" | "detail" | undefined
type Error = { message: string; type: ErrorType }

export class BillingViewModel extends ViewModel {
	private _errorHandler
	private _getInvoicesUseCase: GetInvoicesUseCase
	private _exportInvoicesUseCase: ExportInvoicesUseCase
	private _exportInvoiceTransactionsUseCase: ExportInvoiceTransactionsUseCase
	private _updateInvoicesStatusUseCase: UpdateInvoicesStatusUseCase
	private _updateTransactionCreatedDateUseCase: UpdateTransactionCreatedDateUseCase
	private _updateInvoiceEmissionDateUseCase: UpdateInvoiceEmissionDateUseCase
	isFetching: boolean = false
	limit = 400
	isLoading: boolean = false
	error: Error = { message: "", type: undefined }
	editMode: boolean = false
	invoices: Invoice[] = []
	selectedInvoiceIds: number[] = []
	seletectInvoicesTransactionsIds: number[] = []
	selectedInvoice: Invoice | null = null
	selectedStatus: InvoiceStatus = InvoiceStatus.EMITTED
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	tipTableFilters = {}

	constructor({
		ErrorHandler,
		GetInvoicesUseCase,
		ExportInvoicesUseCase,
		ExportInvoiceTransactionsUseCase,
		UpdateInvoicesStatusUseCase,
		UpdateTransactionCreatedDateUseCase,
		UpdateInvoiceEmissionDateUseCase
	}: Props) {
		super()
		makeObservable(this, {
			isFetching: observable,
			isLoading: observable,
			invoices: observable,
			selectedInvoiceIds: observable,
			selectedInvoice: observable,
			seletectInvoicesTransactionsIds: observable,
			selectedStatus: observable,
			setInvoices: action,
			setLoading: action,
			setIsFetching: action,
			setSelectedInvoiceIds: action,
			setSelectedInvoice: action,
			setSeletectInvoicesTransactionsIds: action,
			cleanPagination: action,
			setSelectedStatus: action
		})
		this._errorHandler = ErrorHandler
		this._getInvoicesUseCase = GetInvoicesUseCase
		this._exportInvoicesUseCase = ExportInvoicesUseCase
		this._updateInvoicesStatusUseCase = UpdateInvoicesStatusUseCase
		this._updateTransactionCreatedDateUseCase = UpdateTransactionCreatedDateUseCase
		this._exportInvoiceTransactionsUseCase = ExportInvoiceTransactionsUseCase
		this._updateInvoiceEmissionDateUseCase = UpdateInvoiceEmissionDateUseCase
		this.fetchInvoices()
	}

	public async fetchInvoices(filter?: InvoicesFilter) {
		try {
			this.setIsFetching(true)
			const invoices = await this._getInvoicesUseCase.exec({
				offset: 0,
				limit: this.limit,
				...filter
			})
			const selectedInvoice = invoices.find(i => i.id === this.selectedInvoice?.id) ?? null
			this.setSelectedInvoice(selectedInvoice)
			this.setSelectedInvoiceIds([])
			this.setInvoices(invoices)
			this.cleanPagination()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	public async exportInvoices(filter?: InvoicesFilter) {
		try {
			this.setLoading(true)
			const file = await this._exportInvoicesUseCase.exec({
				offset: 0,
				limit: this.limit,
				...filter
			})
			const fileService = new FileService()
			const dateString = new Date().toISOString().split("T")[0]
			fileService.dowloadFile(file, `Facturación_${dateString}.csv`)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async exportInvoiceTransactions(invoiceId: number) {
		try {
			this.setLoading(true)
			const file = await this._exportInvoiceTransactionsUseCase.exec(invoiceId)
			const fileService = new FileService()
			const dateString = new Date().toISOString().split("T")[0]
			fileService.dowloadFile(file, `Facturación_Transacciones_${dateString}.csv`)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateInvoicesStatus() {
		if ((this.selectedInvoiceIds?.length ?? 0) === 0) return
		try {
			this.setLoading(true)
			await this._updateInvoicesStatusUseCase.exec({
				invoiceIds: this.selectedInvoiceIds,
				status: this.selectedStatus
			})
			this.fetchInvoices()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateInvoiceEmissionDate(invoiceId: number, emissionDate: string) {
		try {
			this.setInvoices(this.invoices.map(i => (i.id === invoiceId ? { ...i, emissionDate: emissionDate } : i)))
			this.setLoading(true)
			await this._updateInvoiceEmissionDateUseCase.exec({ invoiceId, emissionDate: emissionDate })
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateTransactionCreatedDate(invoiceId: number, createdDate: Date) {
		if ((this.seletectInvoicesTransactionsIds?.length ?? 0) === 0) return
		try {
			this.setLoading(true)
			await this._updateTransactionCreatedDateUseCase.exec({
				invoiceId,
				createdDate,
				transactionIds: this.seletectInvoicesTransactionsIds
			})
			this.setSeletectInvoicesTransactionsIds([])
			await this.fetchInvoices()
			if (this.selectedInvoice === null) {
				window.location.href = "/billing"
			}
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public cleanPagination() {
		this.pagination = 0
		this.setHasNextPage(true)
	}

	// ACTIONS
	setInvoices(invoices: Invoice[]) {
		this.invoices = invoices
	}

	setSelectedInvoiceIds(selectedInvoiceIds: number[]) {
		this.selectedInvoiceIds = selectedInvoiceIds
		console.log(this.selectedInvoiceIds)
	}

	setSeletectInvoicesTransactionsIds(seletectInvoicesTransactionsIds: number[]) {
		this.seletectInvoicesTransactionsIds = seletectInvoicesTransactionsIds
	}

	setSelectedInvoice(invoice: Invoice | null) {
		this.selectedInvoice = invoice
	}

	setSelectedStatus(selectedStatus: InvoiceStatus) {
		this.selectedStatus = selectedStatus
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setIsFetching(isFetching: boolean) {
		this.isFetching = isFetching
	}

	setError(message: string, type: ErrorType) {
		this.error = { message, type }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}
}
