import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { UsersViewModel } from "../../users/UsersViewModel"
import DataTable from "../../../components/DataTable/DataTable"
import { User } from "../../../../domain/entities/User"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import TABLE_COLUMNS from "../const/VOUCHER_USERS_COLUMNS"
import { DeleteFilled } from "@ant-design/icons"
import { colors } from "../../../assets/styles/appStyles"
import { observer } from "mobx-react"
import { VoucherUser } from "../../../../domain/entities/Voucher"

interface VoucherUsersFormSectionProps {
	data: Partial<VoucherUser>[]
	loading?: boolean
	onDelete?: (userId: number) => void
}

const VoucherUsersFormSection = ({ loading, onDelete, data }: VoucherUsersFormSectionProps) => {
	return (
		<DataTable
			loading={loading}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			columns={TABLE_COLUMNS()}
			dataSource={data.map((user, idx) => ({
				key: user.userId,
				id: user.userId,
				name: user.userName,
				email: user.userEmail,
				deleteButton: (
					<div key={user.userId}>
						<DeleteFilled
							style={{ fontSize: "1.2rem", color: colors.error }}
							onClick={() => onDelete && onDelete(user.userId!)}
						/>
					</div>
				)
			}))}
		/>
	)
}

export default observer(VoucherUsersFormSection)
