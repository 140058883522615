import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"

export default () => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.dataTableHeaders.micrositeTips" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true,
		autoSort: false
	},
	{
		title: t("user"),
		dataIndex: "userEmail",
		key: "userEmail"
	},
	{
		title: t("name"),
		dataIndex: "userName",
		key: "userName"
	},
	{
		title: t("weight"),
		dataIndex: "position",
		key: "position",
		inputType: InputType.NUMBER,
		sorteable: true,
		editable: true
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
