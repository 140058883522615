import { Col, Divider, Modal, Row, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { createRef, useEffect, useState } from "react"
import { FormField } from "../../../components/Form/FormField"
import { VoucherUser } from "../../../../domain/entities/Voucher"
import { VoucherViewModel } from "../VoucherViewModel"

export interface VoucherUsersModalProps {
	onConfirm?: (user: VoucherUser) => void
	onCancel?: () => void
	visible: boolean
}

const VoucherUsersModal = ({ visible, onConfirm, onCancel }: VoucherUsersModalProps) => {
	const { t } = useTranslation("vouchers", { keyPrefix: "form.usersInfo" })
	const voucherViewModel = useContainerInjection<VoucherViewModel>("VoucherViewModel")
	const initialModalData = {
		id: undefined
	}
	const [modalData, setModalData] = useState<{ id?: number }>(initialModalData)
	const formRef = createRef<MainFormRef>()

	useEffect(() => {
		setModalData(initialModalData)
	}, [visible])

	return (
		<Modal
			title={
				<div style={{ position: "relative", bottom: 24 }}>
					<Typography.Title level={5}>{t("modalTitle")}</Typography.Title>
					<Divider style={{ marginBottom: 0 }} />
				</div>
			}
			centered
			style={{ overflowY: "auto" }}
			open={visible}
			onOk={async () => {
				await formRef.current?.validateFields()
				const user = voucherViewModel.searchedUsers.find(u => u.id === modalData.id)
				user && onConfirm && onConfirm({ userId: modalData.id!, userName: user.name, userEmail: user.email! })
			}}
			onCancel={() => onCancel && onCancel()}
			width={600}
			okText={t("confirm")}
			cancelText={t("cancel")}
		>
			<MainForm
				initialValues={modalData}
				hideFormButtons
				key={"voucherUsersModal"}
				onChangeFormValues={formData => {
					if (JSON.stringify(modalData) !== JSON.stringify(formData)) {
						setModalData({
							...modalData,
							...formData
						})
					}
				}}
				className="flex flex-col justify-center items-center"
				ref={formRef}
			>
				<Row gutter={[24, 0]} className="w-full">
					<Col md={24} xl={24} xs={24}>
						<Row gutter={[24, 0]}>
							<Col xl={22} md={22} xs={22} className="mx-auto">
								<FormField.SelectSearch
									key={"id"}
									label={t("user")}
									name={"id"}
									mandatory
									value={modalData?.id}
									placeholder={t("userPlaceholder").toString()}
									onChange={selected => {
										setModalData({
											...modalData,
											id: selected.value
										})
									}}
									asyncFetchSearchFunction={async expression =>
										await voucherViewModel.searchUsers(expression)
									}
									options={voucherViewModel.searchedUsers
										.filter(
											u =>
												!voucherViewModel.formData.voucherUsers?.some(
													vu => vu.userId === Number(u.id)
												)
										)
										.map(u => ({
											label: `${u.id} - ${u.email} - ${u.name}`,
											value: Number(u.id),
											title: u.email
										}))}
									popupMatchSelectWidth={true}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>
		</Modal>
	)
}

export default observer(VoucherUsersModal)
