import { Category } from "./Category"
import { ServiceSession } from "./ServiceSesssion"
import { ServicePicture } from "./ServicePicture"
import { File } from "../types/File.type"
import { Seller } from "./Seller"
import { ServiceStatus } from "../enum/serviceStatus.enum"
import { ServiceOffice } from "./ServiceOffice"

export interface ServiceMoreInformation {
	title?: string
	description?: string
	footer?: string
}

interface ContructorParams {
	id: string
	title: string
	title_en?: string
	title_ca?: string
	description?: string
	description_en?: string
	description_ca?: string
	hint?: string
	hint_en?: string
	hint_ca?: string
	price?: number
	typeService?: string
	picture?: string
	pictures?: ServicePicture[]
	seller?: Seller
	isOnline?: boolean
	isPresential?: boolean
	isFoundation?: boolean
	serviceClusters: Partial<Category>[]
	serviceContent?: { pictures: ServicePicture[] }[]
	weight: number
	serviceSessions: ServiceSession[]
	endorsedUser?: { id: string; name: string }
	countryId?: string
	highlighted?: boolean
	endorsedName?: string
	endorsedUserId?: string
	endorsedUsername?: string
	crossSelling?: boolean
	status: ServiceStatus
	askForInformation?: boolean
	offices?: ServiceOffice[]
	hasWaitlist: boolean
	moreInformation?: ServiceMoreInformation | null
}

export class SellerServiceDetail {
	public id: string
	public title: string
	public title_en?: string
	public title_ca?: string
	public description?: string
	public description_en?: string
	public description_ca?: string
	public hint?: string
	public hint_en?: string
	public hint_ca?: string
	public price?: number
	public typeService?: string
	public picture?: string & File
	public pictures?: ServicePicture[] & File
	public isFoundation?: boolean
	public isOnline?: boolean
	public isPresential?: boolean
	public serviceClusters: Partial<Category>[]
	public weight: number
	public serviceSessions: ServiceSession[]
	public serviceContent?: { pictures: ServicePicture[] }[]
	public seller?: Seller
	public countryId?: string
	public highlighted?: boolean
	public endorsedName?: string
	public endorsedUserId?: string
	public endorsedUsername?: string
	public crossSelling: boolean
	public status: ServiceStatus
	public askForInformation?: boolean
	public offices?: ServiceOffice[]
	public hasWaitlist: boolean
	public moreInformation?: ServiceMoreInformation | null

	constructor(params: ContructorParams) {
		this.id = params.id
		this.title = params.title
		this.title_en = params.title_en
		this.title_ca = params.title_ca
		this.description = params.description
		this.description_en = params.description_en
		this.description_ca = params.description_ca
		this.hint = params.hint
		this.hint_en = params.hint_en
		this.hint_ca = params.hint_ca
		this.price = params.price
		this.typeService = params.typeService
		this.picture = params.picture
		this.pictures = params.pictures
		this.seller = params.seller
		this.isFoundation = params.isFoundation
		this.isOnline = params.isOnline
		this.isPresential = params.isPresential
		this.serviceClusters = params.serviceClusters
		this.weight = params.weight
		this.serviceSessions = params.serviceSessions
		this.serviceContent = params.serviceContent
		this.seller = params.seller
		this.countryId = params.countryId
		this.highlighted = params.highlighted
		this.endorsedName = params.endorsedName
		this.endorsedUserId = params.endorsedUserId
		this.endorsedUsername = params.endorsedUsername
		this.crossSelling = params.crossSelling ?? false
		this.status = params.status
		this.askForInformation = params.askForInformation
		this.offices = params.offices
		this.hasWaitlist = params.hasWaitlist
		this.moreInformation = params.moreInformation
	}
}
