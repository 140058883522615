import { InvoiceStatus } from "../../domain/enum/invoiceStatus.enum"
import { Seller } from "./Seller"

export interface InvoiceTransaction {
	id: number
	userEmail: string
	userName?: string
	marketplace?: string
	micrositeId?: number
	micrositeSlug?: string
	serviceTitle: string
	serviceSessionTitle: string
	paymentFinalAmount: number
	units: number
	sellerPrice?: number | null
	sellerFee?: number | null
	reservationDate: string
}

interface ConstructorParams {
	id: number
	invoiceNumber: string
	createdAt: Date
	paymentDate?: string | null
	emissionDate?: string | null
	period: string
	sellerId: number
	status: InvoiceStatus
	total?: number
	transactions?: InvoiceTransaction[]
	seller?: Seller
}

export class Invoice {
	public id: number
	public invoiceNumber: string
	public createdAt: Date
	public paymentDate?: string | null
	public emissionDate?: string | null
	public period: string
	public sellerId: number
	public status: InvoiceStatus
	public total?: number
	public transactions?: InvoiceTransaction[]
	public seller?: Seller

	public constructor(invoice: ConstructorParams) {
		this.id = invoice.id
		this.invoiceNumber = invoice.invoiceNumber
		this.createdAt = invoice.createdAt
		this.paymentDate = invoice.paymentDate
		this.emissionDate = invoice.emissionDate
		this.period = invoice.period
		this.sellerId = invoice.sellerId
		this.status = invoice.status
		this.total = invoice.total
		this.transactions = invoice.transactions
		this.seller = invoice.seller
	}
}
