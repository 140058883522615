import { LandingReview } from "../../domain/entities/LandingReview"

interface ConstructorParams {
	id: number
	contentEs: string
	contentEn: string
	contentCa: string
	reviewerName: string
	reviewerTitleEs: string
	reviewerTitleEn: string
	reviewerTitleCa: string
	image?: string | null
	weight?: number
}

export class LandingReviewModel {
	public id: number
	public contentEs: string
	public contentEn: string
	public contentCa: string
	public reviewerName: string
	public reviewerTitleEs: string
	public reviewerTitleEn: string
	public reviewerTitleCa: string
	public image?: string | null
	public weight?: number

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.contentEs = params.contentEs
		this.contentEn = params.contentEn
		this.contentCa = params.contentCa
		this.reviewerName = params.reviewerName
		this.reviewerTitleEs = params.reviewerTitleEs
		this.reviewerTitleEn = params.reviewerTitleEn
		this.reviewerTitleCa = params.reviewerTitleCa
		this.weight = params.weight
	}

	static fromRawJson = (rawJson: any): LandingReviewModel => {
		return new LandingReviewModel({ ...rawJson })
	}

	static fromDomain = (landingReview: LandingReview): LandingReviewModel => {
		return new LandingReviewModel(landingReview)
	}
}

declare module "./LandingReviewModel" {
	interface LandingReviewModel {
		toDomain(): LandingReview
	}
}

LandingReviewModel.prototype.toDomain = function (): LandingReview {
	const data = this
	return new LandingReview({
		...data
	})
}
