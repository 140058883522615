import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export default () => {
	const { t } = useTranslation("tips", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "user",
		title: t("user"),
		dataIndex: "user_id",
		width: "25%"
	},
	{
		key: "name",
		title: t("name"),
		dataIndex: "userName",
		width: "25%"
	},
	{
		key: "published",
		title: t("published"),
		dataIndex: "published"
	},
	{
		key: "mediaType",
		title: t("mediaType"),
		dataIndex: "mediaType",
		width: 200,
		align: "center"
		// filter: true
	},
	{
		key: "countries",
		title: t("countriesAssociated"),
		dataIndex: "countries",
		filter: true,
		align: "center"
	},
	{
		key: "microsites",
		title: t("micrositesAssociated"),
		dataIndex: "microsites",
		filter: true
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
