import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export default (isAdmin?: boolean) => {
	const { t } = useTranslation("transactions", { keyPrefix: "form.dataTableHeaders" })

	return isAdmin !== undefined ? columns(t).filter(c => isAdmin || !c.needsAdmin) : columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "invoiceId",
		title: t("invoiceId"),
		dataIndex: "invoiceId",
		sorteable: true
	},
	{
		key: "userEmail",
		title: t("userEmail"),
		dataIndex: "userEmail",
		align: "center"
	},
	{
		key: "userName",
		title: t("userName"),
		dataIndex: "userName",
		align: "center",
		width: "8rem"
	},
	{
		key: "site",
		title: t("site"),
		dataIndex: "site",
		align: "center",
		width: "6rem"
	},
	{
		key: "micrositeType",
		title: t("micrositeType"),
		dataIndex: "micrositeType",
		width: "10rem",
		align: "center"
	},
	{
		key: "serviceName",
		title: t("serviceName"),
		dataIndex: "serviceName"
	},
	{
		key: "serviceId",
		title: t("serviceId"),
		dataIndex: "serviceId",
		width: "6rem",
		align: "center"
	},
	{
		key: "sessionName",
		title: t("sessionName"),
		dataIndex: "sessionName"
	},
	{
		key: "sellerName",
		title: t("sellerName"),
		dataIndex: "sellerName",
		align: "center",
		width: "8rem"
	},
	{
		key: "appointmentStatus",
		title: t("appointmentStatus"),
		dataIndex: "appointmentStatus",
		align: "center",
		width: "10rem"
	},
	{
		key: "date",
		title: t("date"),
		dataIndex: "date"
	},
	{
		key: "appointmentDate",
		title: t("appointmentDate"),
		dataIndex: "appointmentDate",
		align: "center"
	},
	{
		key: "finalPrice",
		title: t("finalPrice"),
		dataIndex: "finalPrice",
		width: "8rem",
		align: "center"
	},
	{
		key: "units",
		title: t("units"),
		dataIndex: "units",
		width: "6rem",
		align: "center"
	},
	{
		key: "cancelledAt",
		title: t("cancellationDate"),
		dataIndex: "cancelledAt",
		width: "10rem"
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton",
		needsAdmin: true
	}
]
