import { Col, Form, Row } from "antd"
import MultimediaUploader from "../../../components/MultimediaUploader/MultimediaUploader"
import CustomColorPicker from "../../../components/ColorPicker/ColorPicker"
import { useTranslation } from "react-i18next"
import { Microsite } from "../../../../domain/entities/Microsite"
import { File } from "../../../../domain/types/File.type"

interface MicrositeVisualsFormSectionProps {
	data: Partial<Microsite> & File
	onChange: (value: any) => void
}

const MicrositeVisualsFormSection = ({ data, onChange }: MicrositeVisualsFormSectionProps) => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.visualsInfo" })
	return (
		<Row gutter={[24, 0]}>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<Form.Item label={t("color")} labelCol={{ span: 24 }}>
						<CustomColorPicker value={data?.color} onChange={color => onChange && onChange({ color })} />
					</Form.Item>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<Form.Item label={t("contrastColor")} labelCol={{ span: 24 }}>
						<CustomColorPicker
							value={data?.contrastColor}
							onChange={contrastColor => onChange && onChange({ contrastColor })}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col xl={6} md={24} xs={24}>
					<Form.Item label={t("sporttipsLogo")} labelCol={{ span: 24 }}>
						<MultimediaUploader
							containerStyle={{ width: "240px", height: "160px" }}
							mediaUrl={data?.sporttipsLogo}
							manualUpload
							onClean={() => onChange && onChange({ sporttipsLogo: undefined })}
							onChange={sporttipsLogo => onChange && onChange({ sporttipsLogo })}
						/>
					</Form.Item>
				</Col>
				<Col xl={6} md={24} xs={24}>
					<Form.Item label={t("partnerLogo")} labelCol={{ span: 24 }}>
						<MultimediaUploader
							containerStyle={{ width: "240px", height: "160px" }}
							mediaUrl={data?.partnerLogo}
							manualUpload
							onClean={() => onChange && onChange({ partnerLogo: undefined })}
							onChange={partnerLogo => onChange && onChange({ partnerLogo })}
						/>
					</Form.Item>
				</Col>
				<Col xl={6} md={9} xs={24} className="flex flex-col items-center">
					<Form.Item label={t("infoIcon")} labelCol={{ span: 24 }}>
						<MultimediaUploader
							containerStyle={{ width: "160px", height: "160px" }}
							mediaUrl={data?.infoIcon}
							manualUpload
							onClean={() => onChange && onChange({ infoIcon: undefined })}
							onChange={infoIcon => onChange && onChange({ infoIcon })}
						/>
					</Form.Item>
				</Col>
				<Col xl={6} md={9} xs={24} className="flex flex-col items-center">
					<Form.Item label={t("allClusters")} labelCol={{ span: 24 }}>
						<MultimediaUploader
							containerStyle={{ width: "160px", height: "160px" }}
							mediaUrl={data?.allClusterImage ?? undefined}
							manualUpload
							onClean={() => onChange && onChange({ allClusterImage: undefined })}
							onChange={allClusterImage => onChange && onChange({ allClusterImage })}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Row>
	)
}

export default MicrositeVisualsFormSection
