import { BillingRepository } from "../../../repositories/billingRepository"
import { UseCase } from "../../useCase"
import { ExportInvoiceTransactionsRequest } from "./request"
import { ExportInvoiceTransactionsResponse } from "./response"

export class ExportInvoiceTransactionsUseCase
	implements UseCase<ExportInvoiceTransactionsRequest, ExportInvoiceTransactionsResponse>
{
	private _repository
	constructor({ BillingRepository }: { BillingRepository: BillingRepository }) {
		this._repository = BillingRepository
	}
	exec(filter: ExportInvoiceTransactionsRequest): Promise<ExportInvoiceTransactionsResponse> {
		return this._repository.exportInvoiceTransactions(filter)
	}
}
