import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { GetAllLandingReviewsRequest } from "./request"
import { GetAllLandingReviewsResponse } from "./response"

export class GetAllLandingReviewsUseCase implements UseCase<GetAllLandingReviewsRequest, GetAllLandingReviewsResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec() {
		return this._repository.getAllLandingReviews()
	}
}
