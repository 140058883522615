import { VoucherRules } from "../../data/dto/VoucherRules.dto"
interface ContructorParams {
	id: string
	status: number
	voucherCode: string
	voucherRules?: Partial<VoucherRules>
	voucherUsers?: VoucherUser[]
}

export interface VoucherUser {
	userId: number
	userEmail: string
	userName?: string
}
export enum DiscountType {
	FIXED = "1",
	PERCENTAGE = "2"
}

export class Voucher {
	public id: string
	public status: number
	public voucherCode: string
	public voucherRules?: Partial<VoucherRules>
	public voucherUsers?: VoucherUser[]

	constructor(params: ContructorParams) {
		this.id = params.id
		this.status = params.status
		this.voucherCode = params.voucherCode
		this.voucherRules = params.voucherRules
		this.voucherUsers = params.voucherUsers
	}
}
