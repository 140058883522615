import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default () => {
	const { t } = useTranslation("vouchers", { keyPrefix: "form.usersInfo.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "email",
		title: t("email"),
		dataIndex: "email"
	},
	{
		key: "name",
		title: t("name"),
		dataIndex: "name"
	},
	{
		title: "",
		key: "deleteButton",
		dataIndex: "deleteButton"
	}
]
