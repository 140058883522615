import { action, makeObservable, observable, toJS } from "mobx"
import { FileType, Microsite, MicrositeService } from "../../../domain/entities/Microsite"
import { Currency } from "../../../domain/entities/Currency"
import { PartnerType } from "../../../domain/entities/PartnerType"
import { GetMicrositesUseCase } from "../../../domain/useCases/microsites/getMicrosites"
import { CreateMicrositeUseCase } from "../../../domain/useCases/microsites/createMicrosite"
import { UpdateMicrositeUseCase } from "../../../domain/useCases/microsites/updateMicrosite"
import { DeleteMicrositeUseCase } from "../../../domain/useCases/microsites/deleteMicrosite"
import { GetMicrositeDetailUseCase } from "../../../domain/useCases/microsites/getMicrositeDetail"
import { UploadMicrositeImageUseCase } from "../../../domain/useCases/microsites/uploadMicrositeImage"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { DeleteMicrositeServiceUseCase } from "../../../domain/useCases/microsites/deleteMicrositeService"
import { BannerForm } from "../../components/BannersFormView/entities/BannerForm"
import { SaveBannersUseCase } from "../../../domain/useCases/banners/saveBanners"
import { GetBannersUseCase } from "../../../domain/useCases/banners/getBanners"
import { LandingReview } from "../../../domain/entities/LandingReview"
import { GetAllLandingReviewsUseCase } from "../../../domain/useCases/microsites/getAllLandingReviews"
import { Tip } from "../../../domain/entities/Tip"
import { GetTipsUseCase } from "../../../domain/useCases/tips/getTips"
import { FileService } from "../../../domain/services/FileService"
import { Banner } from "../../../domain/entities/Banner"
import { UploadFile } from "antd"

interface Props {
	GetMicrositesUseCase: GetMicrositesUseCase
	CreateMicrositeUseCase: CreateMicrositeUseCase
	UpdateMicrositeUseCase: UpdateMicrositeUseCase
	DeleteMicrositeUseCase: DeleteMicrositeUseCase
	GetMicrositeDetailUseCase: GetMicrositeDetailUseCase
	UploadMicrositeImageUseCase: UploadMicrositeImageUseCase
	DeleteMicrositeServiceUseCase: DeleteMicrositeServiceUseCase
	SaveBannersUseCase: SaveBannersUseCase
	GetBannersUseCase: GetBannersUseCase
	GetAllLandingReviewsUseCase: GetAllLandingReviewsUseCase
	GetTipsUseCase: GetTipsUseCase
	ErrorHandler: ErrorHandler
}
type ErrorType = "header" | "detail" | undefined
type Error = { message: string; type: ErrorType }

export class MicrositesViewModel {
	private _getMicrositeUseCase
	private _createMicrositeUseCase
	private _updateMicrositeUseCase
	private _deleteMicrositeUseCase
	private _getMicrositeDetailUseCase
	private _uploadMicrositeImageUseCase
	private _deleteMicrositeServiceUseCase
	private _getBannersUseCase
	private _saveBannersUseCase
	private _getTipsUseCase
	private _getAllLandingReviewsUseCase
	private _errorHandler
	private _fileService: FileService

	isLoading: boolean = false
	searchedMicroSite: Partial<Microsite>[] = []
	isLoadingPartnersTypes: boolean = false
	isLoadingDetail: boolean = false
	error: Error = { message: "", type: undefined }
	editMode: boolean = false
	microsites: Partial<Microsite>[] = []
	currencies: Currency[] = [
		{ id: "1", name: "Euro", symbol: "€", isoCode: "EUR", symbolPosition: "after" },
		// { id: "2", name: "US Dolar", symbol: "$", isoCode: "USD", symbolPosition: "before" },
		// { id: "3", name: "Libra esterlina", symbol: "£", isoCode: "GBP", symbolPosition: "before" },
		{ id: "4", name: "Pesos mexicanos", symbol: "$", isoCode: "MXN", symbolPosition: "before" }
	]
	parthnersTypes: PartnerType[] = [
		{ id: "0", typeName: "Genérico", description: "Microsite con pasarela de pago" },
		{ id: "1", typeName: "Corporate Wellness", description: "Microsite sin pasarela de pago" },
		{ id: "2", typeName: "Formulario externo", description: "Microsite con enlace a formularo/url externos" },
		{
			id: "3",
			typeName: "Copago",
			description: "Microsite con pasarela de pago y código de descuento para el copago"
		}
	]
	businessTypes = Microsite.businessTypes
	selectedMicrosite: Partial<Microsite> = {}
	initialFormData: Partial<Microsite> = {}
	formData = this.initialFormData
	originalFormData: Partial<Microsite> = {}
	searchValue: string = ""
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	banners: BannerForm[] = []
	landingReviews: LandingReview[] = []
	tips: Tip[] = []
	searchedTips: Tip[] = []

	constructor({
		GetMicrositesUseCase,
		UpdateMicrositeUseCase,
		CreateMicrositeUseCase,
		DeleteMicrositeUseCase,
		GetMicrositeDetailUseCase,
		UploadMicrositeImageUseCase,
		DeleteMicrositeServiceUseCase,
		GetBannersUseCase,
		SaveBannersUseCase,
		GetAllLandingReviewsUseCase,
		GetTipsUseCase,
		ErrorHandler
	}: Props) {
		makeObservable(this, {
			searchValue: observable,
			isLoading: observable,
			isLoadingPartnersTypes: observable,
			isLoadingDetail: observable,
			error: observable,
			editMode: observable,
			microsites: observable,
			searchedMicroSite: observable,
			selectedMicrosite: observable,
			banners: observable,
			setBanners: action,
			fetchBanners: action,
			setSelectedMicrosite: action,
			formData: observable,
			originalFormData: observable,
			setOriginalFormData: action,
			setFormData: action,
			initialFormData: observable,
			setInitialFormData: action,
			setMicrosites: action,
			setLoading: action,
			setLoadingPartnersTypes: action,
			setLoadingDetail: action,
			setEditMode: action,
			setError: action,
			tableConfig: observable,
			setTableConfig: action,
			tips: observable,
			setTips: action,
			searchedTips: observable,
			setSearchedTips: action,
			landingReviews: observable,
			setLandingReviews: action
		})
		this._getMicrositeUseCase = GetMicrositesUseCase
		this._updateMicrositeUseCase = UpdateMicrositeUseCase
		this._createMicrositeUseCase = CreateMicrositeUseCase
		this._deleteMicrositeUseCase = DeleteMicrositeUseCase
		this._getMicrositeDetailUseCase = GetMicrositeDetailUseCase
		this._uploadMicrositeImageUseCase = UploadMicrositeImageUseCase
		this._deleteMicrositeServiceUseCase = DeleteMicrositeServiceUseCase
		this._getBannersUseCase = GetBannersUseCase
		this._saveBannersUseCase = SaveBannersUseCase
		this._getAllLandingReviewsUseCase = GetAllLandingReviewsUseCase
		this._errorHandler = ErrorHandler
		this._getTipsUseCase = GetTipsUseCase
		this._fileService = new FileService()
		this.fetchMicrosites()
		this.fetchAllTips()
		this.fetchAllLandingReviews()
	}

	public handleError(error: any) {
		this._errorHandler.handleError(error)
	}

	public async fetchAllLandingReviews() {
		try {
			const landingReviews = await this._getAllLandingReviewsUseCase.exec()
			this.setLandingReviews(landingReviews)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		}
	}

	public async fetchAllTips() {
		try {
			const tips = await this._getTipsUseCase.exec({
				pagination: 0,
				limit: 500
			})
			this.setTips(tips)
			this.setSearchedTips(tips)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		}
	}

	public async searchTips(search: string) {
		try {
			const tips = await this._getTipsUseCase.exec({
				searchExpression: search
			})
			this.setSearchedTips(tips)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		}
	}

	public clearSearchedTips() {
		this.setSearchedTips(this.tips)
	}

	public async fetchMicrosites() {
		try {
			this.setLoading(true)
			const microsites = await this._getMicrositeUseCase.exec()
			this.setMicrosites(microsites)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	async fetchBanners() {
		try {
			if (!this.selectedMicrosite?.slug) return
			this.setLoading(true)
			const banners = await this._getBannersUseCase.exec(this.selectedMicrosite.slug)
			this.setBanners(banners.map(b => new BannerForm(b)))
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	async saveBanners(isDuplicate?: boolean) {
		try {
			this.setLoading(true)
			let bannersData: BannerForm[]
			if (isDuplicate) {
				// Download existing banner images to upload them if microsite is a duplicate.
				const images = await Promise.all(
					this.banners.map(async b => {
						// If banner is new it is not necessary to download file.
						if (!b.id) {
							return {
								desktopImage: b.desktopImage as UploadFile,
								mobileImage: b.mobileImage as UploadFile
							}
						}
						const desktopImage = b.image
							? await this._fileService.getFileFromURL(b.image, b.getImageName() ?? crypto.randomUUID())
							: undefined
						const mobileImage = b.image
							? await this._fileService.getFileFromURL(
									Banner.GetMobileImage(b.image),
									b.getImageName() ?? crypto.randomUUID()
							  )
							: undefined
						return { id: b.id, desktopImage, mobileImage }
					})
				)
				const bannerImages = images
					.filter(img => !!img.id)
					.reduce(
						(bannersImages, img) => ({ ...bannersImages, [img.id!]: img }),
						{} as { [id: number]: { desktopImage?: UploadFile | File; mobileImage?: UploadFile | File } }
					)
				bannersData = this.banners.map(b => {
					return new BannerForm(
						{
							...b,
							id: undefined,
							key: this.formData.slug!,
							desktopImage: b.id ? bannerImages[b.id]?.desktopImage : b.desktopImage,
							mobileImage: b.id ? bannerImages[b.id]?.mobileImage : b.mobileImage
						},
						undefined,
						true
					)
				})
			} else {
				bannersData = this.banners
			}
			await this._saveBannersUseCase.exec(bannersData)
			await this.fetchBanners()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchMicrositeDetail(microsite_id: string) {
		try {
			this.setLoadingDetail(true)
			const micrositeDetails = await this._getMicrositeDetailUseCase.exec(microsite_id)
			micrositeDetails.services.sort((a, b) => Number(a.weight) - Number(b.weight))
			const updatedMicrositeData = { ...this.formData, ...micrositeDetails }
			this.setFormData(updatedMicrositeData)
			this.setOriginalFormData(updatedMicrositeData)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoadingDetail(false)
		}
	}

	public async sendFormData() {
		if (this.formData.id) {
			await this.updateMicrosite()
		} else {
			await this.createMicrosite()
		}
	}

	public async createMicrosite() {
		let microsite: Microsite = {} as Microsite
		try {
			this.setLoading(true)
			microsite = await this._createMicrositeUseCase.exec(toJS(this.formData))
			await this.uploadImages(microsite.id!)
			this.fetchMicrosites()
			this.setFormData({ ...this.formData, id: microsite.id, status: true })
			await this.fetchMicrositeDetail(microsite.id!)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateMicrosite() {
		try {
			this.setLoading(true)
			await this._updateMicrositeUseCase.exec(this.formData)
			await this.uploadImages(this.formData.id!)
			await this.fetchMicrosites()
			this.setOriginalFormData(this.formData)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
			await this.fetchMicrositeDetail(this.formData.id!)
		}
	}

	public extractServicesClusters() {
		const clusters =
			this.formData?.services
				?.flatMap(s => s.cluster ?? [])
				?.reduce(
					(clusters, c) => ({ ...clusters, [c.clusterId]: c.clusterName }),
					{} as { [clusterId: number]: string }
				) ?? {}
		return Object.entries(clusters).map(([clusterId, clusterName]) => ({
			clusterId,
			clusterName
		}))
	}

	private async uploadImages(micrositeId: string) {
		const images = [
			{
				image: this.formData?.partnerLogo,
				type: FileType.PARTNER_LOGO
			},
			{
				image: this.formData?.sporttipsLogo,
				type: FileType.SPORTTIPS_LOGO
			},
			{
				image: this.formData?.infoIcon,
				type: FileType.INFO_ICON
			},
			{
				image: this.formData?.allClusterImage,
				type: FileType.ALL_CLUSTERS_IMAGE
			}
		]
		for (let indx = 0; indx < images.length; indx++) {
			const image = images[indx].image
			const fileType = images[indx].type
			//@ts-ignore
			if (image instanceof File) {
				const fileData = new FormData()
				fileData.append("file", image)

				const resp = await this._uploadMicrositeImageUseCase.exec({
					fileData,
					fileName: image.name,
					fileType,
					micrositeId
				})
				let logo
				switch (fileType) {
					case FileType.PARTNER_LOGO:
						logo = { partnerLogo: resp.file_path }
						break
					case FileType.SPORTTIPS_LOGO:
						logo = { sporttipsLogo: resp.file_path }
						break
					case FileType.INFO_ICON:
						logo = { infoIcon: resp.file_path }
						break
					case FileType.ALL_CLUSTERS_IMAGE:
						logo = { allClusterImage: resp.file_path }
						break
				}
				this.setFormData({ ...this.formData, ...logo })
			}
		}
	}

	public async deleteMicrositeService(service: MicrositeService) {
		try {
			if (service?.new) {
				const services = this.formData.services?.filter(item => item.id !== service.id)
				this.setFormData({ ...this.formData, services })
				return
			}
			this.setLoadingDetail(true)
			await this._deleteMicrositeServiceUseCase.exec({ micrositeId: this.formData.id!, serviceId: service.id })
			await this.fetchMicrositeDetail(this.formData.id!)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoadingDetail(false)
		}
	}

	public async deleteMicrosite(microsite_id: string) {
		try {
			this.setLoading(true)
			await this._deleteMicrositeUseCase.exec(microsite_id)
			const microsites = this.microsites.filter(item => item.id !== microsite_id)
			this.setMicrosites(microsites)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public removeCluster(index: number) {
		const micrositeDetails = this.formData?.clusters || []
		micrositeDetails.splice(index, 1)
		this.setFormData({ ...this.formData, clusters: micrositeDetails })
	}

	hasIncompleteBanners() {
		return this.banners.concat(this.banners).some(b => !b.deleted && (!b.desktopImage || !b.mobileImage))
	}

	// ACTIONS
	setMicrosites(microsites: Partial<Microsite>[]) {
		this.microsites = microsites
		this.setSearchedMicroSite(microsites)
	}

	setLandingReviews(landingReviews: LandingReview[]) {
		this.landingReviews = landingReviews
	}

	setTips(tips: Tip[]) {
		this.tips = tips
	}

	setSearchedTips(tips: Tip[]) {
		this.searchedTips = tips
	}

	setBanners(banners: BannerForm[]) {
		this.banners = banners
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setLoadingPartnersTypes(isLoading: boolean) {
		this.isLoadingPartnersTypes = isLoading
	}

	setEditMode(editMode: boolean) {
		this.editMode = editMode
	}

	setInitialFormData(formData: Partial<Microsite>) {
		this.initialFormData = formData
	}

	setFormData(formData: Partial<Microsite>) {
		this.formData = formData
	}

	setOriginalFormData(formData: Partial<Microsite>) {
		this.originalFormData = formData
	}

	setSelectedMicrosite(microsite: Partial<Microsite>) {
		this.selectedMicrosite = microsite
	}

	setSearchedMicroSite(microsites: Partial<Microsite>[]) {
		this.searchedMicroSite = microsites
	}

	setLoadingDetail(isLoading: boolean) {
		this.isLoadingDetail = isLoading
	}

	setError(message: string, type: ErrorType) {
		this.error = { message, type }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}
}
