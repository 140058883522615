import { Col, Row, Select, Tag } from "antd"
import DataTable from "../../../components/DataTable/DataTable"
import { observer } from "mobx-react"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { MicrositesViewModel } from "../MicrositesViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import TABLE_COLUMNS from "../const/MICROSITE_TIPS_TABLE_COLUMNS"
import { DeleteFilled } from "@ant-design/icons"
import { colors } from "../../../assets/styles/appStyles"
import { LandingReview } from "../../../../domain/entities/LandingReview"
import { toJS } from "mobx"

interface Props {
	onChange?: (id: number, value: { tipId: number; position: number }) => void
	onDelete?: (id: number) => void
}

const MicrositeTipsFormSection = ({ onChange, onDelete }: Props) => {
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")

	const handleSave = (record: any) => {
		onChange && onChange(record.id, record)
	}
	return (
		<Row gutter={[24, 0]}>
			<Col md={24} xl={24} xs={24}>
				<DataTable
					loading={viewModel.isLoadingDetail}
					renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
					editableCells={{ handleSave }}
					columns={TABLE_COLUMNS()}
					dataSource={
						viewModel.formData.tips?.map((micrositeTip, idx) => {
							const tip = viewModel.tips.find(t => t.id === micrositeTip.tipId)
							return {
								key: micrositeTip.tipId,
								id: micrositeTip.tipId,
								userEmail: tip?.user?.email,
								userName: tip?.user?.name,
								position: micrositeTip.position,
								actions: (
									<div key={micrositeTip.tipId}>
										<DeleteFilled
											style={{ fontSize: "1.2rem", color: colors.error }}
											onClick={() => onDelete && onDelete(micrositeTip.tipId)}
										/>
									</div>
								)
							}
						}) || []
					}
				/>
			</Col>
		</Row>
	)
}

export default observer(MicrositeTipsFormSection)
