import { Col, Row, Select, Tag } from "antd"
import DataTable from "../../../components/DataTable/DataTable"
import { observer } from "mobx-react"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { MicrositesViewModel } from "../MicrositesViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import TABLE_COLUMNS from "../const/LANDING_REVIEWS_TABLE_COLUMNS"
import { DeleteFilled } from "@ant-design/icons"
import { colors } from "../../../assets/styles/appStyles"
import { LandingReview } from "../../../../domain/entities/LandingReview"

interface Props {
	onChange?: (id: number, value: LandingReview) => void
	onDelete?: (id: number) => void
}

const MicrositeLandingReviewsFormSection = ({ onChange, onDelete }: Props) => {
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")

	const handleSave = (record: any) => {
		onChange && onChange(record.id, record)
	}

	return (
		<Row gutter={[24, 0]}>
			<Col md={24} xl={24} xs={24}>
				<DataTable
					loading={viewModel.isLoadingDetail}
					renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
					editableCells={{ handleSave }}
					columns={TABLE_COLUMNS()}
					dataSource={
						viewModel.formData.landingReviews?.map((review, idx) => ({
							key: review.id,
							id: review.id,
							name: review.reviewerName,
							content: (
								<p
									style={{
										color: "black",
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										maxWidth: "50rem"
									}}
								>
									{review.contentEs}
								</p>
							),
							weight: review.weight,
							actions: (
								<div key={review.id}>
									<DeleteFilled
										style={{ fontSize: "1.2rem", color: colors.error }}
										onClick={() => onDelete && onDelete(review.id)}
									/>
								</div>
							)
						})) || []
					}
				/>
			</Col>
		</Row>
	)
}

export default observer(MicrositeLandingReviewsFormSection)
