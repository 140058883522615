import { Col, Divider, Modal, Row, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { createRef, useEffect, useState } from "react"
import { FormField } from "../../../components/Form/FormField"
import { SellerServicesViewModel } from "../../sellersServices/SellerServicesViewModel"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { MicrositesViewModel } from "../MicrositesViewModel"
import { SellersViewModel } from "../../sellers/SellersViewModel"
import { ServiceStatus } from "../../../../domain/enum/serviceStatus.enum"
import { LandingReview } from "../../../../domain/entities/LandingReview"

export interface MicrositeLandingReviewModalProps {
	onConfirm?: (review: LandingReview) => void
	onCancel?: () => void
	visible: boolean
	editting?: boolean
}

const MicrositeLandingReviewModal = ({ visible, onConfirm, onCancel }: MicrositeLandingReviewModalProps) => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.landingReviewsInfo" })
	const micrositeViewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const initialModalData = {
		id: undefined,
		weight: 1
	}
	const [modalData, setModalData] = useState<{ id?: number; weight: number }>(initialModalData)
	const formRef = createRef<MainFormRef>()

	useEffect(() => {
		setModalData(initialModalData)
	}, [visible])

	return (
		<Modal
			title={
				<div style={{ position: "relative", bottom: 24 }}>
					<Typography.Title level={5}>{t("modalTitle")}</Typography.Title>
					<Divider style={{ marginBottom: 0 }} />
				</div>
			}
			centered
			style={{ overflowY: "auto" }}
			open={visible}
			onOk={async () => {
				await formRef.current?.validateFields()
				const review = micrositeViewModel.landingReviews.find(r => r.id === modalData.id)
				review && onConfirm && onConfirm({ ...review, weight: Number(modalData.weight ?? 1) })
			}}
			onCancel={() => onCancel && onCancel()}
			width={600}
			okText={t("confirm")}
			cancelText={t("cancel")}
		>
			<MainForm
				initialValues={modalData}
				hideFormButtons
				key={"micrositeLandingReviewModal"}
				onChangeFormValues={formData => {
					if (JSON.stringify(modalData) !== JSON.stringify(formData)) {
						setModalData({
							...modalData,
							...formData
						})
					}
				}}
				className="flex flex-col justify-center items-center"
				ref={formRef}
			>
				<Row gutter={[24, 0]} className="w-full">
					<Col md={24} xl={24} xs={24}>
						<Row gutter={[24, 0]}>
							<Col xl={22} md={22} xs={22} className="mx-auto">
								<FormField.SelectSearch
									key={"id"}
									label={t("review")}
									name={"id"}
									mandatory
									value={modalData?.id}
									placeholder={t("reviewPlaceholder").toString()}
									onChange={selected => {
										setModalData({
											...modalData,
											id: selected.value
										})
									}}
									options={micrositeViewModel.landingReviews.map(review => ({
										label: `${review.reviewerName} - ${review.contentEs}`,
										value: review.id,
										title: review.contentEs
									}))}
									popupMatchSelectWidth={true}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={22} md={22} xs={22} className="mx-auto mb-2">
								<FormField.Input
									inputType={InputType.NUMBER}
									key={"weight"}
									label={t("weight")}
									name={"weight"}
									mandatory
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>
		</Modal>
	)
}

export default observer(MicrositeLandingReviewModal)
