import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { Checkbox } from "antd"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { BillingViewModel } from "../BillingViewModel"
import { AuthStore } from "../../../stores/AuthStore"

export default (onSelectAll?: (value: boolean) => void) => {
	const { t } = useTranslation("billing", { keyPrefix: "dataTableHeaders" })
	const viewModel = useContainerInjection<BillingViewModel>("BillingViewModel")
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	return columns(t, viewModel, isAdmin, onSelectAll)
}

const columns = (
	t: TFunction,
	viewModel: BillingViewModel,
	isAdmin: boolean,
	onSelectAll?: (value: boolean) => void
) => [
	isAdmin && {
		title: (
			<div className="flex items-center text-bold gap-2">
				{t("select")}
				<Checkbox
					disabled={viewModel.selectedInvoice?.status !== "pending_emission"}
					key="select_all"
					onChange={value => onSelectAll && onSelectAll(value.target.checked)}
				/>
			</div>
		),
		key: "checkbox",
		dataIndex: "checkbox",
		width: "6rem",
		align: "center"
	},
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		width: "4rem",
		align: "center"
	},
	{
		key: "user",
		title: t("user"),
		width: "10rem",
		dataIndex: "user"
	},
	{
		key: "name",
		title: t("name"),
		width: "10rem",
		dataIndex: "name"
	},
	{
		key: "reservationDate",
		title: t("reservationDate"),
		dataIndex: "reservationDate",
		width: "8rem",
		align: "center"
	},
	{
		key: "period",
		title: t("period"),
		width: "8rem",
		dataIndex: "period"
	},
	{
		key: "microsite",
		title: t("microsite"),
		width: "8rem",
		dataIndex: "microsite"
	},
	{
		key: "marketplace",
		title: t("marketplace"),
		width: "8rem",
		dataIndex: "marketplace"
	},
	{
		key: "service",
		title: t("service"),
		dataIndex: "service"
	},
	{
		key: "session",
		title: t("session"),
		dataIndex: "session"
	},
	{
		key: "paymentDate",
		title: t("paymentDate"),
		dataIndex: "paymentDate",
		width: "8rem",
		align: "center"
	},
	{
		key: "units",
		title: t("units"),
		width: "4rem",
		dataIndex: "units",
		align: "right"
	},
	{
		key: "sellerPrice",
		title: t("sellerPrice"),
		width: "6rem",
		dataIndex: "sellerPrice",
		align: "right"
	},
	{
		key: "commission",
		title: t("fee"),
		width: "4rem",
		dataIndex: "commission",
		align: "right"
	},
	{
		key: "total",
		title: t("total"),
		dataIndex: "total",
		width: "6rem",
		align: "right"
	}
]
