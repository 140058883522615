import { action, configure, makeObservable, observable } from "mobx"
import { CreateVoucherUseCase } from "../../../domain/useCases/vouchers/createVoucher"
import { UpdateVoucherUseCase } from "../../../domain/useCases/vouchers/updateVoucher"
import { GetSellerServicesUseCase } from "../../../domain/useCases/sellersServices/getSellersServices"
import { GetVouchersUseCase } from "../../../domain/useCases/vouchers/getVouchers"
import { DeleteVoucherUseCase } from "../../../domain/useCases/vouchers/deleteVoucher"
import { DiscountType, Voucher } from "../../../domain/entities/Voucher"
import { UploadVoucherPictureUseCase } from "../../../domain/useCases/vouchers/uploadVoucherPicture"
import { VoucherRules } from "../../../data/dto/VoucherRules.dto"
import { GetVoucherDetailUseCase } from "../../../domain/useCases/vouchers/getVoucherDetail"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { Country } from "../../../domain/entities/Country"
import { GetCountriesUseCase } from "../../../domain/useCases/countries/getCountries"
import { SellerService } from "../../../domain/entities/SellerService"
import { GetUsersUseCase } from "../../../domain/useCases/users/getUsersUseCase"
import { User } from "../../../domain/entities/User"
import { SearchUsersUseCase } from "../../../domain/useCases/users/searchUsers"

interface ContructorParams {
	GetSellerServicesUseCase: GetSellerServicesUseCase
	GetVouchersUseCase: GetVouchersUseCase
	CreateVoucherUseCase: CreateVoucherUseCase
	UpdateVoucherUseCase: UpdateVoucherUseCase
	DeleteVoucherUseCase: DeleteVoucherUseCase
	UploadVoucherPictureUseCase: UploadVoucherPictureUseCase
	GetVoucherDetailUseCase: GetVoucherDetailUseCase
	GetCountriesUseCase: GetCountriesUseCase
	GetUsersUseCase: GetUsersUseCase
	SearchUsersUseCase: SearchUsersUseCase
	ErrorHandler: ErrorHandler
}

export class VoucherViewModel {
	private _getUsersUseCase
	private _getSellerServicesUseCase
	private _getVouchersUseCase
	private _updateVoucherUseCase
	private _createVoucherUseCase
	private _deleteVoucherUseCase
	private _uploadPictureUseCase
	private _getVoucherDetailUseCase
	private _errorHandler
	private _getCountriesUseCase
	private _searchUsersUseCase

	initialFormData: Partial<Voucher> = {}
	originalFormData = this.initialFormData
	formData = this.initialFormData
	editMode: boolean = false
	selectedVoucher: Partial<Voucher> = {}
	searchedVouchers: Partial<Voucher>[] = []
	pagination: number = 0
	isLoading: boolean = false
	isLoadingDetail: boolean = false
	isLoadingFields: boolean = false
	isLoadingSellers: boolean = false
	vouchers: Partial<Voucher>[] = []
	sellersServices: Partial<SellerService>[] = []
	countries: Country[] = []
	searchValue: string = ""
	discountTypes = [
		{ value: DiscountType.FIXED, label: "fixed" },
		{ value: DiscountType.PERCENTAGE, label: "percentage" }
	]
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	users: Partial<User>[] = []
	searchedUsers: Partial<User>[] = []

	constructor({
		GetSellerServicesUseCase,
		GetVouchersUseCase,
		CreateVoucherUseCase,
		UpdateVoucherUseCase,
		UploadVoucherPictureUseCase,
		DeleteVoucherUseCase,
		GetVoucherDetailUseCase,
		GetCountriesUseCase,
		GetUsersUseCase,
		SearchUsersUseCase,
		ErrorHandler
	}: ContructorParams) {
		configure({
			enforceActions: "never"
		})
		makeObservable(this, {
			isLoading: observable,
			isLoadingDetail: observable,
			vouchers: observable,
			editMode: observable,
			searchedVouchers: observable,
			setSelectedVoucher: action,
			selectedVoucher: observable,
			searchValue: observable,
			setLoading: action,
			setLoadingDetail: action,
			setEditMode: action,
			formData: observable,
			originalFormData: observable,
			setOriginalFormData: action,
			setFormData: action,
			initialFormData: observable,
			setInitialFormData: action,
			updateVoucher: action,
			tableConfig: observable,
			setTableConfig: action,
			countries: observable,
			setCountries: action,
			sellersServices: observable,
			setSellersServices: action,
			isLoadingFields: observable,
			setIsLoadingFields: action,
			users: observable,
			searchedUsers: observable,
			setUsers: action,
			setSearchedUsers: action
		})
		this._getVouchersUseCase = GetVouchersUseCase
		this._getSellerServicesUseCase = GetSellerServicesUseCase
		this._createVoucherUseCase = CreateVoucherUseCase
		this._updateVoucherUseCase = UpdateVoucherUseCase
		this._deleteVoucherUseCase = DeleteVoucherUseCase
		this._uploadPictureUseCase = UploadVoucherPictureUseCase
		this._getVoucherDetailUseCase = GetVoucherDetailUseCase
		this._getCountriesUseCase = GetCountriesUseCase
		this._getUsersUseCase = GetUsersUseCase
		this._searchUsersUseCase = SearchUsersUseCase
		this._errorHandler = ErrorHandler
		this.fetchVouchers()
		this.fetchSellersServices()
	}

	public async fetchUsers() {
		try {
			this.setLoading(true)
			const users = await this._getUsersUseCase.exec({
				pagination: 0,
				limit: 500
			})
			this.setUsers(users)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async searchUsers(expression: string) {
		if (expression === "") {
			this.setSearchedUsers(this.users)
			return
		}
		this.setLoading(true)
		try {
			const searchedUsers = await this._searchUsersUseCase.exec({ expression })
			this.setSearchedUsers(searchedUsers)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchVouchers() {
		this.setLoading(true)
		try {
			const vouchers = await this._getVouchersUseCase.exec({ pagination: this.pagination })
			const countries = await this._getCountriesUseCase.exec()
			this.setVouchers(vouchers)
			this.setCountries(countries)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchSellersServices() {
		this.setIsLoadingFields(true)
		try {
			const sellersServices = await this._getSellerServicesUseCase.exec({ pagination: 0, limit: 1000 })
			this.setSellersServices(sellersServices)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsLoadingFields(false)
		}
	}

	public async fetchVoucherDetail(voucher_id: string) {
		this.setLoadingDetail(true)
		try {
			if (!voucher_id) throw "Voucher id is required"
			const voucher = await this._getVoucherDetailUseCase.exec(voucher_id)
			this.setFormData(voucher)
			this.setOriginalFormData(voucher)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoadingDetail(false)
		}
	}

	public async createVoucher(newVoucher: Partial<Voucher>) {
		this.setLoading(true)
		try {
			const createdVoucher = await this._createVoucherUseCase.exec(newVoucher)
			await this.fetchVouchers()
			this.editModeOn(createdVoucher)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public editModeOn(voucher: Partial<Voucher>) {
		this.setEditMode(true)
		const newData = { ...this.formData, voucher_rules: voucher.voucherRules, id: voucher.id }
		this.setFormData(newData)
		this.setOriginalFormData(newData)
	}

	public async updateVoucher(voucher: Partial<Voucher>) {
		this.setLoading(true)
		try {
			await this._updateVoucherUseCase.exec(voucher)
			await this.fetchVouchers()
			this.setOriginalFormData({ ...this.formData })
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async deleteVoucher(voucher: Partial<Voucher>) {
		this.setLoading(true)
		try {
			await this._deleteVoucherUseCase.exec(voucher.id || "")
			await this.fetchVouchers()
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async uploadPicture(uploadData: any) {
		try {
			const file = await this._uploadPictureUseCase.exec(uploadData)
			const { onSuccess } = uploadData.methods
			onSuccess && onSuccess({ url: file.file_path })
		} catch (error) {
			const { onError } = uploadData.methods
			onError && onError(error)
		}
	}

	setVouchers(vouchers: Partial<Voucher>[]) {
		this.vouchers = vouchers
		this.setSearchedVouchers(vouchers)
	}

	setSelectedVoucher(voucher: Partial<Voucher>) {
		this.selectedVoucher = voucher
	}

	setLoading(loading: boolean) {
		this.isLoading = loading
	}

	setIsLoadingFields(loading: boolean) {
		this.isLoadingFields = loading
	}

	setLoadingDetail(loading: boolean) {
		this.isLoadingDetail = loading
	}

	setPagination(pagination: number) {
		this.pagination = pagination
	}

	updateVoucherState(voucherId: string, updatedVoucher: Partial<Voucher>) {
		this.setVouchers(this.vouchers.map(voucher => (voucher.id === voucherId ? updatedVoucher : voucher)))
	}

	setSearchedVouchers(vouchers: Partial<Voucher>[]) {
		this.searchedVouchers = vouchers
	}

	setFormData(data: Partial<Voucher>) {
		this.formData = data
	}

	setInitialFormData(data: Partial<Voucher>) {
		this.initialFormData = data
	}

	setOriginalFormData(data: Partial<Voucher>) {
		this.originalFormData = data
	}

	public handleRulesFormChange(rules: Partial<VoucherRules>) {
		const newFormData = { ...this.formData?.voucherRules, ...rules }
		this.setFormData({ ...this.formData, voucherRules: newFormData })
	}

	setEditMode(state: boolean) {
		this.editMode = state
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}

	setCountries(countries: Country[]) {
		this.countries = countries
	}

	setSellersServices(sellersServices: Partial<SellerService>[]) {
		this.sellersServices = sellersServices
	}

	setUsers(users: Partial<User>[]) {
		this.users = users
		this.setSearchedUsers(users)
	}

	setSearchedUsers(users: Partial<User>[]) {
		this.searchedUsers = users
	}
}
