import { UploadFile } from "antd"
import { Banner, BannerParams } from "../../../../domain/entities/Banner"

export class BannerForm extends Banner {
	viewId: string
	desktopImage?: string | UploadFile | File
	mobileImage?: string | UploadFile | File
	changed = false
	deleted = false

	constructor(
		params: BannerParams & { desktopImage?: string | UploadFile | File; mobileImage?: string | UploadFile | File },
		viewId?: string,
		changed?: boolean
	) {
		super(params)
		this.viewId = viewId ?? crypto.randomUUID()
		this.desktopImage = params.desktopImage ?? params.image
		this.mobileImage = params.mobileImage ?? (params.image ? Banner.GetMobileImage(params.image) : undefined)
		this.changed = changed ?? false
	}
	setDesktopImage(image?: string | UploadFile) {
		this.desktopImage = image
		this.changed = true
	}
	setMobileImage(image?: string | UploadFile) {
		this.mobileImage = image
		this.changed = true
	}
	setChanged(value: boolean) {
		this.changed = value
	}
	setHref(href: string): void {
		super.setHref(href)
		this.changed = true
	}
	delete(): void {
		this.deleted = true
	}
	undoDelete(): void {
		this.deleted = false
	}
}
