import { DeleteCategoryUseCase } from "./../domain/useCases/categories/deleteCategory"
import { UpdateCategoryUseCase } from "./../domain/useCases/categories/updateCategory"
import { CreateCategoryUseCase } from "./../domain/useCases/categories/createCategory"
import { CreateUserUseCase } from "./../domain/useCases/users/createUserUseCase"
import { SearchUsersUseCase } from "./../domain/useCases/users/searchUsers"
import { CreateSellerServiceUseCase } from "./../domain/useCases/sellersServices/createSellerService"
import { CreateServiceContentUseCase } from "./../domain/useCases/sellersServices/createServiceContent"
import { UpdateSellerServiceUseCase } from "./../domain/useCases/sellersServices/updateSellerService"
import { GetSellerServiceExcelUseCase } from "./../domain/useCases/sellersServices/getSellerServiceExcel"
import { GetUserDetailUseCase } from "../domain/useCases/users/getUserDetailUseCase"
import { UserRepositoryImpl } from "./../data/repositories/UserRepository"
import { UserRepository } from "./../domain/repositories/userRepository"
import { CountryRepository } from "./../domain/repositories/countryRepository"
import { CountryRepositoryImpl } from "./../data/repositories/CountryRepository"
import { UserService } from "./../data/services/UserService"
import { ServiceSessionService } from "../data/services/ServiceSessionService"
import { CategoryService } from "./../data/services/CategoryService"
import { CountryService } from "./../data/services/CountryService"
import { CategoryRepositoryImpl } from "./../data/repositories/CategoryRepository"
import { GetCategoriesUseCase } from "./../domain/useCases/categories/getCategories"
import { GetCategoryIconsUseCase } from "./../domain/useCases/categories/getCategoryIcons"
import { GetEnglishFieldsUseCase } from "./../domain/useCases/categories/getCategoriesEnglishFields"
import { UpdateSellerUseCase } from "./../domain/useCases/sellers/updateSeller"
import { CreateSellerUseCase } from "./../domain/useCases/sellers/createSeller"
import { GetAutoCompleteSellersUseCase } from "./../domain/useCases/sellers/autoCompleteSellers"
import { SellerServicesRepositoryImpl } from "./../data/repositories/SellerServiceRepository"
import { createContainer, asClass } from "awilix"
import { AxioshHttpManager } from "../data/network/Http"
import { HttpManager } from "../data/network/interface/HttpManager"
import { SellersService } from "../data/services/SellersService"
import { SellerServicesService } from "../data/services/SellerServicesService"
import { SellersRepositoryImpl } from "../data/repositories/SellerRepository"
import { ServiceSessionRepositoryImpl } from "../data/repositories/ServiceSessionRepository"
import { AuthRepositoryImpl } from "../data/repositories/AuthRepository"
import { VoucherRepositoryImpl } from "../data/repositories/VoucherRepository"
import { MicrositeRepositoryImpl } from "../data/repositories/MicrositeRepository"
import { SellerRepository } from "../domain/repositories/sellerRepository"
import { AuthRepository } from "../domain/repositories/AuthRepository"
import { SellerServicesRepository } from "../domain/repositories/sellerServicesRepository"
import { SellersViewModel } from "../ui/pages/sellers/SellersViewModel"
import { SellerServicesViewModel } from "../ui/pages/sellersServices/SellerServicesViewModel"
import { GetSellersUseCase } from "../domain/useCases/sellers/getSellers"
import { GetSellerServicesUseCase } from "../domain/useCases/sellersServices/getSellersServices"
import { GetSellerServiceDetailUseCase } from "../domain/useCases/sellersServices/getSellerServiceDetail"
import { UpdateSellerServiceStatusUseCase } from "../domain/useCases/sellersServices/updateSellerServiceStatus"
import { SearchSellersServicesUseCase } from "../domain/useCases/sellersServices/searchSellerServices"
import { UploadMultipleSellerServicePictureUseCase } from "../domain/useCases/sellersServices/uploadMultipleSellerServicePicturesUseCase"
import { CategoriesViewModel } from "../ui/pages/categories/CategoriesViewModel"
import { TransactionsViewModel } from "../ui/pages/transactions/TransactionsViewModel"
import { CategoryRepository } from "../domain/repositories/categoryRepository"
import { DeleteServiceSessionUseCase } from "../domain/useCases/serviceSessions/deleteSession"
import { UpdateServiceSessionUseCase } from "../domain/useCases/serviceSessions/updateSession"
import { CreateServiceSessionUseCase } from "../domain/useCases/serviceSessions/createSession"
import { ServiceSessionRepository } from "../domain/repositories/serviceSessionRepository"
import { CreateVoucherUseCase } from "../domain/useCases/vouchers/createVoucher"
import { UpdateVoucherUseCase } from "../domain/useCases/vouchers/updateVoucher"
import { DeleteVoucherUseCase } from "../domain/useCases/vouchers/deleteVoucher"
import { LoginUseCase } from "../domain/useCases/auth/signIn"
import { GoogleLoginUseCase } from "../domain/useCases/auth/googleSignIn"
import { GetVouchersUseCase } from "../domain/useCases/vouchers/getVouchers"
import { GetVoucherDetailUseCase } from "../domain/useCases/vouchers/getVoucherDetail"
import { VoucherRepository } from "../domain/repositories/voucherRepository"
import { MicrositeRepository } from "../domain/repositories/micrositeRepository"
import { VoucherService } from "../data/services/VoucherService"
import { MicrositeService } from "../data/services/MicrositeService"
import { VoucherViewModel } from "../ui/pages/vouchers/VoucherViewModel"
import { UploadVoucherPictureUseCase } from "../domain/useCases/vouchers/uploadVoucherPicture"
import { MicrositesViewModel } from "../ui/pages/microSites/MicrositesViewModel"
import { GetMicrositesUseCase } from "../domain/useCases/microsites/getMicrosites"
import { GetMicrositeDetailUseCase } from "../domain/useCases/microsites/getMicrositeDetail"
import { CreateMicrositeUseCase } from "../domain/useCases/microsites/createMicrosite"
import { UpdateMicrositeUseCase } from "../domain/useCases/microsites/updateMicrosite"
import { DeleteMicrositeUseCase } from "../domain/useCases/microsites/deleteMicrosite"
import { UploadMicrositeImageUseCase } from "../domain/useCases/microsites/uploadMicrositeImage"
import { UploadMicrositeDetailImageUseCase } from "../domain/useCases/microsites/uploadMicrositeDetailImage"
import { SignInViewModel } from "../ui/pages/signIn/SignInViewModel"
import { AuthService } from "../data/services/AuthService"
import { Translation } from "../ui/stores/TranslationStore"
import { AuthStore } from "../ui/stores/AuthStore"
import { ErrorHandler } from "../ui/error/ErrorHandler"
import { Settings } from "../ui/stores/Settings"
import { SettingsViewModel } from "../ui/pages/settings/SettingsViewModel"
import { UsersViewModel } from "../ui/pages/users/UsersViewModel"
import { GetUsersUseCase } from "../domain/useCases/users/getUsersUseCase"
import { GetCountriesUseCase } from "../domain/useCases/countries/getCountries"
import { GetCategoryDetailUseCase } from "../domain/useCases/categories/getCategoryDetail"
import { UpdateUserUseCase } from "../domain/useCases/users/updateUser/createUserUseCase"
import { DeleteMicrositeServiceUseCase } from "../domain/useCases/microsites/deleteMicrositeService"
import { TipRepository } from "../domain/repositories/tipRepository"
import { TipRepositoryImpl } from "../data/repositories/TipRepository"
import { TipService } from "../data/services/TipService"
import { TipsViewModel } from "../ui/pages/tips/TipsViewModel"
import { GetTipsUseCase } from "../domain/useCases/tips/getTips"
import { CreateTipUseCase } from "../domain/useCases/tips/createTip"
import { UpdateTipUseCase } from "../domain/useCases/tips/updateTip"
import { DeleteMicrositeTipUseCase } from "../domain/useCases/tips/deleteMicrositeTip"
import { TransactionsService } from "../data/services/TransactionsService"
import { TransactionsRepositoryImpl } from "../data/repositories/TransactionsRepository"
import { UploadTransactionsUseCase } from "../domain/useCases/transactions/uploadTransactions"
import { GetTransactionsUseCase } from "../domain/useCases/transactions/getTransactions"
import { GetUploadProfilePictureUrlUseCase } from "../domain/useCases/users/getUploadPictureUrl"
import { ClientFileUploadUseCase } from "../domain/useCases/upload/clientFileUpload"
import { ClientFileUploadRepositoryImpl } from "../data/repositories/ClientFileUploadRepository"
import { ClientFileUploadService } from "../data/services/ClientFileUploadService"
import { UploadTipMediaUseCase } from "../domain/useCases/tips/uploadTipMedia"
import { ImportUsersUseCase } from "../domain/useCases/users/importUsers"
import { BannersViewModel } from "../ui/pages/banners/BannersViewModel"
import { SaveBannersUseCase } from "../domain/useCases/banners/saveBanners"
import { GetBannersUseCase } from "../domain/useCases/banners/getBanners"
import { BannersRepository } from "../domain/repositories/bannersRepository"
import { BannersRepositoryImpl } from "../data/repositories/BannersRepository"
import { BannerService } from "../data/services/BannerService"
import { ReportService } from "../data/services/ReportService"
import { GetAdminReportUseCase } from "../domain/useCases/reports/getAdminReport"
import { ReportsViewModel } from "../ui/pages/reports/ReportsViewModel"
import { ReportRepository } from "../domain/repositories/reportRepository"
import { ReportRepositoryImpl } from "../data/repositories/ReportRepository"
import { DownloadTransactionsUseCase } from "../domain/useCases/transactions/downloadTransactions"
import { ForgotViewModel } from "../ui/pages/forgot/ForgotViewModel"
import { ForgotPasswordUseCase } from "../domain/useCases/auth/forgot"
import { UpdateTransactionUseCase } from "../domain/useCases/transactions/updateTransaction"
import { GetVoucherRulesUseCase } from "../domain/useCases/vouchers/getVoucherRules"
import { CreateTransactionUseCase } from "../domain/useCases/transactions/createTransaction"
import { BillingViewModel } from "../ui/pages/billing/BillingViewModel"
import { BillingRepository } from "../domain/repositories/billingRepository"
import { BillingService } from "../data/services/BillingService"
import { BillingRepositoryImpl } from "../data/repositories/BillingRepository"
import { GetInvoicesUseCase } from "../domain/useCases/billing/getInvoices"
import { ExportInvoicesUseCase } from "../domain/useCases/billing/exportInvoices"
import { UpdateInvoicesStatusUseCase } from "../domain/useCases/billing/updateInvoicesStatus"
import { UpdateTransactionCreatedDateUseCase } from "../domain/useCases/billing/updateTransactions"
import { ExportInvoiceTransactionsUseCase } from "../domain/useCases/billing/exportInvoiceTransactions"
import { GetAllLandingReviewsUseCase } from "../domain/useCases/microsites/getAllLandingReviews"
import { UpdateInvoiceEmissionDateUseCase } from "../domain/useCases/billing/updateInvoiceEmissionDate"

const container = createContainer()

container.register({
	// SERVICES
	ErrorHandler: asClass<ErrorHandler>(ErrorHandler).scoped(),
	HttpManager: asClass<HttpManager>(AxioshHttpManager).scoped(),
	SellersService: asClass<SellersService>(SellersService).scoped(),
	SellerServicesService: asClass<SellerServicesService>(SellerServicesService).scoped(),
	CategoryService: asClass<CategoryService>(CategoryService).scoped(),
	VoucherService: asClass<VoucherService>(VoucherService).scoped(),
	MicrositeService: asClass<MicrositeService>(MicrositeService).scoped(),
	TipService: asClass<TipService>(TipService).scoped(),
	UserService: asClass<UserService>(UserService).scoped(),
	AuthService: asClass<AuthService>(AuthService).scoped(),
	ServiceSessionService: asClass<ServiceSessionService>(ServiceSessionService).scoped(),
	CountryService: asClass<CountryService>(CountryService).scoped(),
	TransactionsService: asClass<TransactionsService>(TransactionsService).scoped(),
	ClientFileUploadService: asClass<ClientFileUploadService>(ClientFileUploadService).scoped(),
	BannerService: asClass<BannerService>(BannerService).scoped(),
	ReportService: asClass<ReportService>(ReportService).scoped(),
	BillingService: asClass<BillingService>(BillingService).scoped(),

	// RERPOSITORIES
	SellerRepository: asClass<SellerRepository>(SellersRepositoryImpl).scoped(),
	SellerServicesRepository: asClass<SellerServicesRepository>(SellerServicesRepositoryImpl).scoped(),
	CategoryRepository: asClass<CategoryRepository>(CategoryRepositoryImpl).scoped(),
	VoucherRepository: asClass<VoucherRepository>(VoucherRepositoryImpl).scoped(),
	MicrositeRepository: asClass<MicrositeRepository>(MicrositeRepositoryImpl).scoped(),
	TipRepository: asClass<TipRepository>(TipRepositoryImpl).scoped(),
	UserRepository: asClass<UserRepository>(UserRepositoryImpl).scoped(),
	AuthRepository: asClass<AuthRepository>(AuthRepositoryImpl).scoped(),
	ServiceSessionRepository: asClass<ServiceSessionRepository>(ServiceSessionRepositoryImpl).scoped(),
	CountryRepository: asClass<CountryRepository>(CountryRepositoryImpl).scoped(),
	TransactionsRepository: asClass<TransactionsRepositoryImpl>(TransactionsRepositoryImpl).scoped(),
	ClientFileUploadRepository: asClass<ClientFileUploadRepositoryImpl>(ClientFileUploadRepositoryImpl).scoped(),
	BannersRepository: asClass<BannersRepository>(BannersRepositoryImpl).scoped(),
	ReportRepository: asClass<ReportRepository>(ReportRepositoryImpl).scoped(),
	BillingRepository: asClass<BillingRepository>(BillingRepositoryImpl).scoped(),

	// USE CASES
	GetSellersUseCase: asClass<GetSellersUseCase>(GetSellersUseCase).scoped(),
	CreateSellerUseCase: asClass<CreateSellerUseCase>(CreateSellerUseCase).scoped(),
	UpdateSellerUseCase: asClass<UpdateSellerUseCase>(UpdateSellerUseCase).scoped(),
	GetSellerServicesUseCase: asClass<GetSellerServicesUseCase>(GetSellerServicesUseCase).scoped(),
	GetSellerServiceDetailUseCase: asClass<GetSellerServiceDetailUseCase>(GetSellerServiceDetailUseCase).scoped(),
	UploadMultipleSellerServicePictureUseCase: asClass<UploadMultipleSellerServicePictureUseCase>(
		UploadMultipleSellerServicePictureUseCase
	).scoped(),
	// Categories use cases
	GetCategoriesUseCase: asClass<GetCategoriesUseCase>(GetCategoriesUseCase).scoped(),
	GetCategoryIconsUseCase: asClass<GetCategoryIconsUseCase>(GetCategoryIconsUseCase).scoped(),
	GetCategoryDetailUseCase: asClass<GetCategoryDetailUseCase>(GetCategoryDetailUseCase).scoped(),
	CreateCategoryUseCase: asClass<CreateCategoryUseCase>(CreateCategoryUseCase).scoped(),
	UpdateCategoryUseCase: asClass<UpdateCategoryUseCase>(UpdateCategoryUseCase).scoped(),
	DeleteCategoryUseCase: asClass<DeleteCategoryUseCase>(DeleteCategoryUseCase).scoped(),
	GetEnglishFieldsUseCase: asClass<GetEnglishFieldsUseCase>(GetEnglishFieldsUseCase).scoped(),
	// Users use cases
	GetUserDetailUseCase: asClass<GetUserDetailUseCase>(GetUserDetailUseCase).scoped(),
	CreateUserUseCase: asClass<CreateUserUseCase>(CreateUserUseCase).scoped(),
	UpdateUserUseCase: asClass<UpdateUserUseCase>(UpdateUserUseCase).scoped(),
	SearchUsersUseCase: asClass<SearchUsersUseCase>(SearchUsersUseCase).scoped(),
	GetUsersUseCase: asClass<GetUsersUseCase>(GetUsersUseCase).scoped(),
	GetUploadProfilePictureUrlUseCase: asClass<GetUploadProfilePictureUrlUseCase>(
		GetUploadProfilePictureUrlUseCase
	).scoped(),
	ImportUsersUseCase: asClass<ImportUsersUseCase>(ImportUsersUseCase).scoped(),
	// Sellers services use cases
	CreateSellerServiceUseCase: asClass<CreateSellerServiceUseCase>(CreateSellerServiceUseCase).scoped(),
	CreateServiceContentUseCase: asClass<CreateServiceContentUseCase>(CreateServiceContentUseCase).scoped(),
	UpdateSellerServiceUseCase: asClass<UpdateSellerServiceUseCase>(UpdateSellerServiceUseCase).scoped(),
	UpdateSellerServiceStatusUseCase: asClass<UpdateSellerServiceStatusUseCase>(
		UpdateSellerServiceStatusUseCase
	).scoped(),
	SearchSellersServicesUseCase: asClass<SearchSellersServicesUseCase>(SearchSellersServicesUseCase).scoped(),
	DeleteServiceSessionUseCase: asClass<DeleteServiceSessionUseCase>(DeleteServiceSessionUseCase).scoped(),
	UpdateServiceSessionUseCase: asClass<UpdateServiceSessionUseCase>(UpdateServiceSessionUseCase).scoped(),
	CreateServiceSessionUseCase: asClass<CreateServiceSessionUseCase>(CreateServiceSessionUseCase).scoped(),
	// Vouchers use cases
	GetVouchersUseCase: asClass<GetVouchersUseCase>(GetVouchersUseCase).scoped(),
	GetVoucherRulesUseCase: asClass<GetVoucherRulesUseCase>(GetVoucherRulesUseCase).scoped(),
	GetVoucherDetailUseCase: asClass<GetVoucherDetailUseCase>(GetVoucherDetailUseCase).scoped(),
	CreateVoucherUseCase: asClass<CreateVoucherUseCase>(CreateVoucherUseCase).scoped(),
	UpdateVoucherUseCase: asClass<UpdateVoucherUseCase>(UpdateVoucherUseCase).scoped(),
	DeleteVoucherUseCase: asClass<DeleteVoucherUseCase>(DeleteVoucherUseCase).scoped(),
	UploadVoucherPictureUseCase: asClass<UploadVoucherPictureUseCase>(UploadVoucherPictureUseCase).scoped(),
	ClientFileUploadUseCase: asClass<ClientFileUploadUseCase>(ClientFileUploadUseCase).scoped(),
	// Microsites use cases
	GetMicrositesUseCase: asClass<GetMicrositesUseCase>(GetMicrositesUseCase).scoped(),
	GetAutoCompleteSellersUseCase: asClass<GetAutoCompleteSellersUseCase>(GetAutoCompleteSellersUseCase).scoped(),
	GetSellerServiceExcelUseCase: asClass<GetSellerServiceExcelUseCase>(GetSellerServiceExcelUseCase).scoped(),
	GetMicrositeDetailUseCase: asClass<GetMicrositeDetailUseCase>(GetMicrositeDetailUseCase).scoped(),
	UpdateMicrositeUseCase: asClass<UpdateMicrositeUseCase>(UpdateMicrositeUseCase).scoped(),
	CreateMicrositeUseCase: asClass<CreateMicrositeUseCase>(CreateMicrositeUseCase).scoped(),
	DeleteMicrositeServiceUseCase: asClass<DeleteMicrositeServiceUseCase>(DeleteMicrositeServiceUseCase).scoped(),
	DeleteMicrositeUseCase: asClass<DeleteMicrositeUseCase>(DeleteMicrositeUseCase).scoped(),
	UploadMicrositeImageUseCase: asClass<UploadMicrositeImageUseCase>(UploadMicrositeImageUseCase).scoped(),
	UploadMicrositeDetailImageUseCase: asClass<UploadMicrositeDetailImageUseCase>(
		UploadMicrositeDetailImageUseCase
	).scoped(),
	LoginUseCase: asClass<LoginUseCase>(LoginUseCase).scoped(),
	ForgotPasswordUseCase: asClass<ForgotPasswordUseCase>(ForgotPasswordUseCase).scoped(),
	GoogleLoginUseCase: asClass<GoogleLoginUseCase>(GoogleLoginUseCase).scoped(),
	GetCountriesUseCase: asClass<GetCountriesUseCase>(GetCountriesUseCase).scoped(),
	GetAllLandingReviewsUseCase: asClass<GetAllLandingReviewsUseCase>(GetAllLandingReviewsUseCase).scoped(),
	// Tips use cases
	CreateTipUseCase: asClass<CreateTipUseCase>(CreateTipUseCase).scoped(),
	GetTipsUseCase: asClass<GetTipsUseCase>(GetTipsUseCase).scoped(),
	UpdateTipUseCase: asClass<UpdateTipUseCase>(UpdateTipUseCase).scoped(),
	DeleteMicrositeTipUseCase: asClass<DeleteMicrositeTipUseCase>(DeleteMicrositeTipUseCase).scoped(),
	UploadTipMediaUseCase: asClass<UploadTipMediaUseCase>(UploadTipMediaUseCase).scoped(),
	// Transactions use cases
	UploadTransactionsUseCase: asClass<UploadTransactionsUseCase>(UploadTransactionsUseCase).scoped(),
	CreateTransactionUseCase: asClass<CreateTransactionUseCase>(CreateTransactionUseCase).scoped(),
	UpdateTransactionUseCase: asClass<UpdateTransactionUseCase>(UpdateTransactionUseCase).scoped(),
	GetTransactionsUseCase: asClass<GetTransactionsUseCase>(GetTransactionsUseCase).scoped(),
	DownloadTransactionsUseCase: asClass<DownloadTransactionsUseCase>(DownloadTransactionsUseCase).scoped(),
	// Banners use cases
	GetBannersUseCase: asClass<GetBannersUseCase>(GetBannersUseCase).scoped(),
	SaveBannersUseCase: asClass<SaveBannersUseCase>(SaveBannersUseCase).scoped(),
	//Reports use cases
	GetAdminReportUseCase: asClass<GetAdminReportUseCase>(GetAdminReportUseCase).scoped(),
	//Billing use cases
	GetInvoicesUseCase: asClass<GetInvoicesUseCase>(GetInvoicesUseCase).scoped(),
	ExportInvoicesUseCase: asClass<ExportInvoicesUseCase>(ExportInvoicesUseCase).scoped(),
	ExportInvoiceTransactionsUseCase: asClass<ExportInvoiceTransactionsUseCase>(
		ExportInvoiceTransactionsUseCase
	).scoped(),
	UpdateInvoicesStatusUseCase: asClass<UpdateInvoicesStatusUseCase>(UpdateInvoicesStatusUseCase).scoped(),
	UpdateTransactionCreatedDateUseCase: asClass<UpdateTransactionCreatedDateUseCase>(
		UpdateTransactionCreatedDateUseCase
	).scoped(),
	UpdateInvoiceEmissionDateUseCase: asClass<UpdateInvoiceEmissionDateUseCase>(
		UpdateInvoiceEmissionDateUseCase
	).scoped(),

	// VIEW MODELS
	SellersViewModel: asClass<SellersViewModel>(SellersViewModel).singleton(),
	SellerServicesViewModel: asClass<SellerServicesViewModel>(SellerServicesViewModel).singleton(),
	CategoriesViewModel: asClass<CategoriesViewModel>(CategoriesViewModel).singleton(),
	VoucherViewModel: asClass<VoucherViewModel>(VoucherViewModel).singleton(),
	MicrositesViewModel: asClass<MicrositesViewModel>(MicrositesViewModel).singleton(),
	TipsViewModel: asClass<TipsViewModel>(TipsViewModel).singleton(),
	SignInViewModel: asClass<SignInViewModel>(SignInViewModel).singleton(),
	ForgotViewModel: asClass<ForgotViewModel>(ForgotViewModel).singleton(),
	SettingsViewModel: asClass<SettingsViewModel>(SettingsViewModel).singleton(),
	UsersViewModel: asClass<UsersViewModel>(UsersViewModel).singleton(),
	TransactionsViewModel: asClass<TransactionsViewModel>(TransactionsViewModel).singleton(),
	BannersViewModel: asClass<BannersViewModel>(BannersViewModel).singleton(),
	ReportsViewModel: asClass<ReportsViewModel>(ReportsViewModel).singleton(),
	BillingViewModel: asClass<BillingViewModel>(BillingViewModel).singleton(),

	// STORES
	AuthStore: asClass<AuthStore>(AuthStore).singleton(),
	Translation: asClass<Translation>(Translation).singleton(),
	Settings: asClass<Settings>(Settings).singleton()
})
type HttpManagerType = "HttpManager"
type ServicesType =
	| "SellersService"
	| "SellerServicesService"
	| "CategoryService"
	| "UserService"
	| "VoucherService"
	| "ClientFileUploadService"
	| "ReportService"
	| "CountryService"
	| "BillingService"
type RepositoriesType =
	| "SellerRepository"
	| "SellerServicesRepository"
	| "CategoryRepository"
	| "UserRepository"
	| "VoucherRepository"
	| "ClientFileUploadRepository"
	| "ReportRepository"
	| "BillingRepository"
type UseCasesType =
	| "GetSellersUseCase"
	| "CreateSellerUseCase"
	| "UpdateSellerUseCase"
	| "GetSellersServicesUseCase"
	| "GetCategoriesUseCase"
	| "GetCategoryIconsUseCase"
	| "GetCategoryDetailUseCase"
	| "GetUserDetailUseCase"
	| "CreateUserUseCase"
	| "SearchUsersUseCase"
	| "CreateSellerServiceUseCase"
	| "CreateServiceContentUseCase"
	| "GetAutoCompleteSellersUseCase"
	| "GetBannersUseCase"
	| "SaveBannersUseCase"
	| "GetInvoicesUseCase"
type ViewModelsType =
	| "SellersViewModel"
	| "SellerServicesViewModel"
	| "CategoriesViewModel"
	| "VoucherViewModel"
	| "MicrositesViewModel"
	| "TipsViewModel"
	| "SignInViewModel"
	| "ForgotViewModel"
	| "SettingsViewModel"
	| "UsersViewModel"
	| "TransactionsViewModel"
	| "BannersViewModel"
	| "ReportsViewModel"
	| "BillingViewModel"
type Store = "AuthStore" | "Translation" | "Settings"
type ErrorHandlerType = "ErrorHandler"
export type ContainerType =
	| HttpManagerType
	| ServicesType
	| RepositoriesType
	| UseCasesType
	| ViewModelsType
	| Store
	| ErrorHandlerType

export { container }
