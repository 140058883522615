import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { Row, Col } from "antd"
import { FormField } from "../../../components/Form/FormField"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import { ServiceMoreInformation } from "../../../../domain/entities/SellerServiceDetail"

interface Props {
	data: Partial<ServiceMoreInformation>
	onChange?: (value: Partial<ServiceMoreInformation>) => void
}

const MicrositeMoreInformationFormSection = ({ data, onChange }: Props) => {
	const { t } = useTranslation("services", { keyPrefix: "form.serviceInfo" })
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")

	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24}>
					<FormField.Input
						value={data?.title}
						key={"title"}
						label={t("moreInformation.title")}
						onChange={(title: string) => onChange && onChange({ title })}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24}>
					<FormField.Input
						value={data?.description}
						key={"description"}
						label={t("moreInformation.description")}
						onChange={(description: string) => onChange && onChange({ description })}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24}>
					<FormField.Input
						value={data?.footer}
						key={"footer"}
						label={t("moreInformation.footer")}
						onChange={(footer: string) => onChange && onChange({ footer })}
					/>
				</Col>
			</Row>
		</>
	)
}

export default observer(MicrositeMoreInformationFormSection)
