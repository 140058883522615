import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { EditFilled } from "@ant-design/icons"
import { Tag, Tooltip, Typography } from "antd"
import { Link } from "react-router-dom"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { toJS } from "mobx"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { useTranslation } from "react-i18next"
import { Tip } from "../../../../domain/entities/Tip"
import { TipsViewModel } from "../TipsViewModel"
import { colors } from "../../../assets/styles/appStyles"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface TipsTableProps {
	data: Partial<Tip>[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const TipsTable = ({ data, loading, isFetching, onChangePage }: TipsTableProps) => {
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const { t } = useTranslation("tips")
	const { t: tCommon } = useTranslation("common")
	const getColor = (labelLength: number) => (labelLength > 18 ? "geekblue" : labelLength > 12 ? "cyan" : "blue")

	const handlePageChange = (page: number, pageSize: number, lastPageFetched: number) => {
		viewModel.setTableConfig({ pageSize })
		onChangePage && onChangePage(page, pageSize, lastPageFetched)
	}

	return (
		<DataTable
			loading={loading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			isFetching={isFetching}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={handlePageChange}
			columns={TABLE_COLUMNS()}
			filterFunctionAsync={(columnKey, values) =>
				viewModel.fetchTipsWithFilters({ ...viewModel.tipTableFilters, [columnKey]: values })
			}
			columnFilters={{
				microsites: viewModel.extractAssociatedMicrosites().map(slug => {
					const label: string = slug
					return {
						text: label,
						value: label
					}
				}),
				countries: viewModel.extractAssociatedCountries().map(country => ({
					text: country,
					value: country
				})),
				mediaType: viewModel.mediaType.map(mediaType => {
					const label: string = mediaType.value
					return {
						text: label,
						value: label
					}
				})
			}}
			dataSource={data.map((tip, idx) => {
				return {
					key: tip.id,
					id: tip.id,
					user_id: tip.user?.email,
					userName: tip.user?.name,
					// mediaType: (<div className="flex w-[6rem]"><Typography.Text>{tip.mediaType}</Typography.Text></div>),
					mediaType: tip.mediaType,
					published: (
						<Typography
							style={{
								color: Number(tip.published) ? colors.success : colors.error,
								fontWeight: "bold"
							}}
						>
							{tip.published ? tCommon("active") : tCommon("inactive")}
						</Typography>
					),
					countries: (() => {
						return (
							<div
								style={{
									minWidth: "11rem",
									maxWidth: "20rem"
								}}
							>
								{tip.countries?.length
									? tip.countries?.map((country, idx) => (
											<Tag style={{ marginBottom: 3 }} key={idx}>
												{`${country.countryId}`}
											</Tag>
									  ))
									: undefined}
							</div>
						)
					})(),
					microsites: (() => {
						const microsites = tip.microsites
						return (
							<div className="h-[3.18rem] overflow-hidden flex-wrap max-w-xs min-w-[11rem] flex">
								{microsites?.length
									? microsites?.map((microsite, idx) => {
											if (idx > 2) return null
											return (
												<>
													<Tag
														className="mb-1 h-[1.4rem]"
														key={idx}
														color={getColor(microsite?.slug?.length)}
													>
														{`${microsite.micrositeId} - ${microsite.slug}`}
													</Tag>
													{idx == 2 ? (
														<div className="absolute bottom-[1.15rem] -right-[0.25rem]">
															<Tag className="flex items-center justify-center bg-gray-200 text-gray-500 rounded-full w-6 h-6">
																+{microsites.length - 2}
															</Tag>
														</div>
													) : null}
												</>
											)
									  })
									: null}
							</div>
						)
					})(),
					actions: (
						<div className="ant-employed">
							<Link to={RoutePaths.TIPS_ADD_NEW} state={{ tip: toJS(tip) }}>
								<Tooltip title={tCommon("edit")}>
									<EditFilled />
								</Tooltip>
							</Link>
						</div>
					)
				}
			})}
		/>
	)
}

export default observer(TipsTable)
